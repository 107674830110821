<div>
    <article *ngFor="let result of results$ | async">
        <h1>{{ result.annee }}</h1>
        <ul>
            <li *ngFor="let file of result.resultats">
                <a href="{{ file.url }}" target="_blank">
                    <i class="material-icons">file_download</i>
                    <span>{{ file.nom_course }}</span>
                </a>
            </li>
        </ul>
    </article>
</div>
