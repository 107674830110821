<nav [class.opened]="open" [class.expanded]="expanded">
    <div class="logo-container">
        <img
            [src]="logoUrl$ | async"
            height="100"
            width="260"
            class="tdt"
            routerLink="/"
            alt="logo"
        />
        <div class="event-date">
            <div class="day">{{ day$ | async }}</div>
            <div class="month">{{ month$ | async }}</div>
            <div class="year">{{ year$ | async }}</div>
        </div>
        <a
            class="logo tuc"
            aria-label="lien TUC Triathlon"
            href="https://www.tuc-triathlon.com"
            target="_blank"
        ></a>
    </div>
    <ul class="nav-menu">
        <li [hidden]="open">
            <a routerLink="/"><i class="icon material-icons">home</i></a>
        </li>
        <li *ngFor="let page of pages$ | async">
            <a
                *ngIf="page.url.startsWith('http')"
                target="_blank"
                [attr.href]="page.url"
                class="focus"
                >{{ page.page_name }}</a
            >
            <a
                *ngIf="!page.url.startsWith('http')"
                routerLink="{{ page.url }}"
                routerLinkActive="selected"
                (click)="open = !open"
                >{{ page.page_name }}</a
            >
        </li>
    </ul>
    <ul class="social">
        <li *ngIf="(config$ | async)?.facebook_url">
            <a
                class="facebook"
                target="_blank"
                href="{{ (config$ | async)?.facebook_url }}"
                aria-label="facebook"
            ></a>
        </li>
        <li *ngIf="(config$ | async)?.twitter_url">
            <a
                class="twitter"
                target="_blank"
                href="{{ (config$ | async)?.twitter_url }}"
                aria-label="twitter"
            ></a>
        </li>
        <li *ngIf="(config$ | async)?.linkedin_url">
            <a
                class="linkedin"
                target="_blank"
                href="{{ (config$ | async)?.linkedin_url }}"
                aria-label="twitter"
            ></a>
        </li>
        <li *ngIf="(config$ | async)?.instagram_url">
            <a
                class="instagram"
                target="_blank"
                href="{{ (config$ | async)?.instagram_url }}"
                aria-label="instagram"
            ></a>
        </li>
    </ul>
    <div class="menu">
        <i class="material-icons" (click)="open = !open">menu</i>
    </div>
</nav>
