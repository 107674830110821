<tdt-submenu></tdt-submenu>
<div>
    <article *ngFor="let article of articles$ | async" class="article">
        <h2 *ngIf="article.titre">{{ article?.titre }}</h2>
        <p *ngIf="article.created_on" class="article-date">
            {{ article?.created_on | date: 'dd MMMM YYYY' }}
        </p>
        <p *ngIf="article.description" [innerHtml]="article?.description | safeHtml"></p>
        <div class="media" *ngIf="article?.url_video">
            <iframe
                *ngIf="article?.url_video"
                height="100%"
                width="100%"
                [src]="article.url_video | safeUrl"
                allowfullscreen
                [title]="article.titre"
            ></iframe>
        </div>
        <img *ngIf="article?.image" [src]="article.image.data.url" [alt]="article.titre" />
    </article>
</div>
